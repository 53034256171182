var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.country",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.country ? _c('country-flag', {
          attrs: {
            "country-iso": item.country
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell.vat",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.country ? _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.vat))]) : _vm._e()];
      }
    }, {
      key: "cell.vies_valid",
      fn: function fn(_ref3) {
        var _ref3$item = _ref3.item,
          viesValid = _ref3$item.vies_valid,
          viesDate = _ref3$item.last_vies_validation_date;
        return [_c('div', {
          staticClass: "text-center"
        }, [viesValid ? _c('i', {
          staticClass: "far fa-check-circle",
          style: {
            color: '#00CBA8'
          },
          attrs: {
            "id": "table-vies-validation-info"
          }
        }) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "target": "table-vies-validation-info"
          }
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t("Last saved VIES validation")))]), _c('br'), _c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.moment(viesDate).format(_vm.dateFormat.concat(" HH:mm:ss"))))])])], 1)];
      }
    }, {
      key: "cell.counterpart",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "font-weight-bolder h-100"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.counterpart_name))])];
      }
    }, {
      key: "cell.is_active",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "label label-dot mr-2",
          class: {
            'label-success': item.is_active,
            'label-danger': !item.is_active
          }
        }), _c('span', {
          staticClass: "font-weight-bold",
          class: {
            'text-success': item.is_active,
            'text-danger': !item.is_active
          }
        }, [_vm._v(_vm._s(item.is_active ? _vm.$t("ACTIVE") : _vm.$t("NOT ACTIVE")))])];
      }
    }, {
      key: "cell.from_date",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(item.from_date ? _vm.DateService.format(item.from_date, _vm.dateFormat) : ""))])];
      }
    }, {
      key: "cell.to_date",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(item.to_date ? _vm.DateService.format(item.to_date, _vm.dateFormat) : ""))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "disabled": !item.attachment,
            "title": _vm.$t('Download attachment')
          },
          on: {
            "click": function click($event) {
              return _vm.download(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.onEdit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.$t('Delete'),
            "size": "btn btn-icon btn-light btn-hover-primary btn-sm"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  }), _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var handleSubmit = _ref9.handleSubmit;
        return [_c('b-modal', {
          ref: "editModal",
          attrs: {
            "size": "xl",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
              }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Edit Vat")))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.editModal.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.update);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('legal-person-vat-form', {
          ref: "formComponent",
          attrs: {
            "existing-form": _vm.editModal.item
          },
          on: {
            "input": _vm.onLegalPersonVatFormInput
          }
        })], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }