<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">{{ $t('VAT') }} </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t('Update your vats') }}</span>
          </div>
          <b-button size="sm" class="text-right font-weight-bolder my-2" variant="light-primary"
            @click="openModal('create-vat-modal')">
            <span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Files/File.svg" />
            </span>
            <span>{{ $t("Add Vat") }}</span>
          </b-button>
        </div>
        <div class="card-body detail">
          <legal-person-vat-table ref="legalPersonVatTable" :legal-person="legalPerson"></legal-person-vat-table>
        </div>
      </div>
    </div>
    <LegalPersonCreateVatModal :existing-form="{}" :legal-person="legalPerson" :countries="countries"
      @refresh="onRefresh">
    </LegalPersonCreateVatModal>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import LegalPersonVatTable from "@/view/components/tables/legal-person/LegalPersonVatTable.vue";
import LegalPersonCreateVatModal from "@/view/components/modals/legal-person/LegalPersonCreateVatModal.vue";
import { mapState } from "vuex";

export default {
  components: {
    LegalPersonVatTable,
    LegalPersonCreateVatModal,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      loading: false,
      counterparts: [],
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("VAT") },
    ]);
    //Open the modal from Create National CCIAA Certificate form , "ADD VAT"
    if (this.$route.params.openModal) {
      this.openModal('create-vat-modal')
    }
  },
  methods: {
    onRefresh() {
      this.$refs.legalPersonVatTable.refresh();
    },
    openModal(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },
  },
};
</script>
