<template>
  <div>
    <datatable ref="datatableRef" :table-props="tableProps" :total="total" :fields="fields" :options.sync="tableOptions"
      :per-page-options="perPageOptions">
      <template #[`cell.country`]="{ item }">
        <country-flag v-if="item.country" :country-iso="item.country"></country-flag>
      </template>

      <template #[`cell.vat`]="{ item }">
        <span v-if="item.country" class="font-weight-bolder">{{ item.vat }}</span>
      </template>

      <template #[`cell.vies_valid`]="{ item: { vies_valid: viesValid, last_vies_validation_date: viesDate } }">
        <div class="text-center">
          <i v-if="viesValid" id="table-vies-validation-info" class="far fa-check-circle"
            :style="{ color: '#00CBA8' }"></i>
          <b-tooltip target="table-vies-validation-info">
            <strong>{{ $t("Last saved VIES validation") }}</strong>
            <br />
            <span class="font-weight-bolder text-muted">{{ moment(viesDate).format(dateFormat.concat(" HH:mm:ss"))
              }}</span>
          </b-tooltip>
        </div>
      </template>

      <template #[`cell.counterpart`]="{ item }">
        <div class="font-weight-bolder h-100">{{ item?.counterpart_name }}</div>
      </template>

      <template #[`cell.is_active`]="{ item }">
        <span class="label label-dot mr-2"
          :class="{ 'label-success': item.is_active, 'label-danger': !item.is_active }"></span><span
          class="font-weight-bold" :class="{ 'text-success': item.is_active, 'text-danger': !item.is_active }">{{
      item.is_active ? $t("ACTIVE") : $t("NOT ACTIVE")
    }}</span>
      </template>

      <template #[`cell.from_date`]="{ item }">
        <span class="font-weight-bolder text-muted">{{ item.from_date ? DateService.format(item.from_date, dateFormat) :
      "" }}</span>
      </template>

      <template #[`cell.to_date`]="{ item }">
        <span class="font-weight-bolder text-muted">{{ item.to_date ? DateService.format(item.to_date, dateFormat) : ""
          }}</span>
      </template>

      <template #[`cell.actions`]="{ item }">
        <div class="d-flex">
          <b-button v-b-tooltip.hover :disabled="!item.attachment"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" :title="$t('Download attachment')"
            @click="download(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Clip.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
            @click="onEdit(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Design/Edit.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Delete')" size="btn btn-icon btn-light btn-hover-primary btn-sm"
            @click="remove(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </b-button>
        </div>
      </template>
    </datatable>

    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
      <b-modal ref="editModal" size="xl" hide-footer @hidden="onModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>{{ $t("Edit Vat") }}</h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2"
                :class="{ 'spinner spinner-light spinner-right': editModal.isSaving }" @click="handleSubmit(update)">
                {{ $t("Save Changes") }}
              </b-button>
              <b-button @click="closeModal">
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </template>
        <legal-person-vat-form ref="formComponent" :existing-form="editModal.item"
          @input="onLegalPersonVatFormInput"></legal-person-vat-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import Datatable from "@/view/components/tables/Datatable.vue";
import LegalPersonVatForm from "@/view/components/forms/legal-person/LegalPersonVatForm.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";
import DateService from "@/core/services/date.service";
import icons from "@/core/config/icons";
import CompanyVatService from "@/core/services/company/company-vat.service";
import { deleteSwal, backendErrorSwal } from "@/core/helpers/swal";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    LegalPersonVatForm,
    Datatable,
    CountryFlag,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      DateService,
      icons,
      moment,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true, class: "align-middle" },
        {
          label: this.$t("Vat"),
          key: "vat",
          sortable: true,
          class: ["align-middle"],
        },
        {
          label: this.$t("VIES validation"),
          key: "vies_valid",
        },
        {
          label: this.$t("From Date"),
          key: "from_date",
          sortable: true
        },
        {
          label: this.$t("To Date"),
          key: "to_date",
          sortable: true
        },
        {
          label: this.$t("Is Active"),
          key: "is_active",
          sortable: true,
        },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      editModal: {
        item: null,
        isSaving: false,
        apiErrors: null,
        isDownloading: false,
      },
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
      };
    },
  },

  watch: {
    "editModal.apiErrors": {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          country: [...(val?.country ?? [])],
          vat: [...(val?.vat ?? [])],
          from_date: [...(val?.from_date ?? [])],
          to_date: [...(val?.to_date ?? [])],
          attachment_tus: [...(val?.attachment_tus ?? [])],
          counterpart: [...(val?.counterpart ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    itemProvider(ctx, callback) {
      if (!this.legalPerson.id) {
        callback([]);
        return;
      }

      CompanyVatService.getAllByCompanyPaginated(this.legalPerson.id, {
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "vat",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        fields: 'id,country,vies_valid,last_vies_validation_date,counterpart_name,is_active,from_date,to_date,attachment,vat'
      }).then((res) => {
        this.total = res.count;
        callback(res.results);
      }).catch(() => callback([]));

      return null;
    },

    onEdit(item) {
      const modal = this.$refs.editModal;
      this.editModal.item = { ...item };
      modal.show();
    },

    closeModal() {
      const modal = this.$refs.editModal;
      modal.hide();
    },

    onModalHidden() {
      this.editModal.apiErrors = null;
      this.editModal.item = null;
    },

    async update() {
      this.$refs.formComponent.updateForm();
      this.editModal.isSaving = true;
      try {
        await CompanyVatService.update(this.editModal.item, this.editModal.item.id);
        this.refresh();
        this.closeModal();
        let title = this.$t("Document Updated")
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (typeof err == "object") {
          this.editModal.apiErrors = err;
          return;
        }
        backendErrorSwal(err);
      } finally {
        this.editModal.isSaving = false;
      }
    },

    remove(item) {
      deleteSwal().then(async (res) => {
        if (res.isConfirmed) {
          try {
            await CompanyVatService.delete(item.id);
            this.refresh();
          } catch (err) {
            backendErrorSwal(err);
          }
        }
      });
    },

    async download(item) {
      try {
        this.editModal.isDownloading = true;
        const res = await CompanyVatService.getOne(item.id);
        window.open(res.attachment, "_blank");
      } catch (err) {
        console.log(err);
        backendErrorSwal(err);
      } finally {
        this.editModal.isDownloading = false;
      }
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    getExtendedCountry(iso) {
      const country = this.countries.find((el) => el.iso == iso);
      if (!country) {
        return iso;
      }
      return country.name;
    },

    onLegalPersonVatFormInput(form) {
      this.editModal.item = form;
    },
  },
};
</script>
